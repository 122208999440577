.stats-holder.football{
    position: relative;
    width: 100%;
    margin: 0 auto;
}

.stats-teams {
    width: 100%;
    text-align: center;
}


.stats-teams__team {
    width: 20%;
    position: relative;
}

.stats-teams__label {
    width: 60%;
}

.x50{
    width: 50%;
    position: relative;
}
